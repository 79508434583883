<template>
  <div class="base-info-contoiner">
    <a-row class="detail-form-row">
      <a-col :span="8">电梯注册码: <span :title="formDatas.liftnum" class="value-text">{{formDatas.liftnum}}</span></a-col>
      <a-col :span="8">救援码: <span :title="formDatas.liftrescue" class="value-text">{{formDatas.liftrescue}}</span></a-col>
      <a-col :span="8">使用场所: <span :title="placeidDetail" class="value-text">{{placeidDetail}}</span></a-col>
    </a-row>
    <a-row class="detail-form-row">
      <a-col :span="8">安装地址: <span :title="formDatas.adress" class="value-text">{{formDatas.adress}}</span></a-col>
      <a-col :span="8">用户设备类别: <span :title="devicetypeidDetail" class="value-text">{{devicetypeidDetail}}</span></a-col>
      <a-col :span="8">站数: <span :title="formDatas.stepnum" class="value-text">{{formDatas.stepnum}}</span></a-col>
    </a-row>
    <a-row class="detail-form-row">
      <a-col :span="8">使用单位: <span :title="userdepidDetail" class="value-text">{{userdepidDetail}}</span></a-col>
      <a-col :span="8">使用单位负责人: <span :title="formDatas.userdeppreson" class="value-text">{{formDatas.userdeppreson}}</span></a-col>
      <a-col :span="8">负责人电话: <span :title="formDatas.userdeppersonphone" class="value-text">{{formDatas.userdeppersonphone}}</span></a-col>
    </a-row>
    <a-row class="detail-form-row">
      <a-col :span="8">维保单位: <span :title="maintenancedepidDetail" class="value-text">{{maintenancedepidDetail}}</span></a-col>
      <a-col :span="8">维保单位负责人: <span :title="formDatas.maintenancedepid_maintenanceperson1" class="value-text">{{formDatas.maintenancedepid_maintenanceperson1}}</span></a-col>
      <a-col :span="8">负责人电话: <span :title="formDatas.maintenancedepid_maintenancephone1" class="value-text">{{formDatas.maintenancedepid_maintenancephone1}}</span></a-col>
    </a-row>
  </div>
</template>
<script>
import moment from 'moment'
import { getParentTreeBycode, getDictByDicType, getMentenacegroupListByCondition } from 'A/xtpz'
import { getTreeDeepString } from 'U'
import { getLiftListByCondition, getLiftinfoById, getParentTreeByid } from 'A/jcgn'
export default {
  props: ['liftid'],
  data() {
    return {
      moment,
      formDatas: {
        // 基础信息
        liftid: '',
        liftnum: '',
        liftrescue: '',
        traceabilitynum: '',
        devicecode: '',
        registerdep: '',
        registertime: '',
        registeruptime: '',
        liftcode: '',
        factorynum: '',
        factorylicense: '',
        operator: '',
        areacode: '',
        adress: '',
        adminuserdepid: '',
        coordinatex: '',
        coordinatey: '',
        status: 1,//0-未使用，1-使用
        placeid: '',
        level: '',
        // 设备信息
        devicetypeid: '',
        devicemodelid: '',
        devicemodelname: '',
        brandid: '',
        devicelicense: '',
        devicelicstarttime: '',
        devicelicendtime: '',
        devicelicenselevel: '',
        dragtype: '',
        floornum: '',
        floorcount: '',
        stepnum: '',
        doornum: '',
        speed: '',
        weight: '',
        // 设备使用标志和登记信息证件查看
        regesternum: '',
        userflagurl: '',
        userloginurl: '',
        // 使用单位信息
        userdepid: '',
        userdepid_depnum: '',
        userdepid_businessnum: '',
        userdepid_address: '',
        userdepid_liftpropertyname: '',
        userdepid_mailcode: '',
        userdepid_safeperson: '',
        userdepid_safephone: '',
        userdepid_liftpropertyid: '',
        userdeppreson: '',
        userdeppersonphone: '',
        estatemodelid: '',
        communityid: '',
        communityname: '',
        // 产权单位信息
        propertydep: '',
        propertydepnum: '',
        propertydepadr: '',
        propertydeppost: '',
        propertydepperson: '',
        propertydepadmin: '',
        propertydepphone: '',
        installtime: '',
        usetime: '',
        // 设备维保信息
        maintenancedepid: '',
        maintenancedepid_depnum: '',//维保单位代码
        maintenancedepid_certificatenum: '',//资质证书代码
        maintenancedepid_businessnum: '',//营业执照代码
        maintenancedepid_licensenum: '',//电梯安装许可证编码
        maintenancedepid_address: '',//单位地址
        maintenancedepid_rescuepreson: '',//救援人员
        maintenancedepid_rescuephone: '',//救援人员电话
        maintenancedepid_maintenanceperson1: '',//维保负责人1
        maintenancedepid_maintenancephone1: '',//维保负责人1电话
        maintenancedepid_maintenanceperson2: '',//维保负责人2
        maintenancedepid_maintenancephone2: '',//维保负责人2电话
        maintenancedepid_areamanager: '',//区域经理
        maintenancedepid_areamanagerphone: '',//区域经理电话
        maintenancedepid_depmanager: '',//单位经理
        maintenancedepid_depmanagerphone: '',//单位经理电话
        maintenancedepid_mailcode: '',//单位经理电话
        maintenancetype: '',
        maintenancegroupid: '',
        // 检验信息
        examinedepid: '',
        testdepid: '',
        rescuedepid: '',
        bigrepairtime: '',
        bigrepaircycle: '',
        // 维保合同信息
        maintenancecontacturl: '',
        maintenancecontacttime: '',
        // IP语音网关和可视通讯
        ipnetnum: '',
        videonum: '',
        // 保险信息
        insurancedep: '',
        insurancenum: '',
        insurancephone: '',
        insuranceexpiretime: '',
        // 设备安装信息
        installdep: '',
        installdepnum: '',
        installlicnum: '',
        installadminphone: '',
        installadmin: '',
        constructiondep: '',
        constructiontime: '',
        acceptdep: '',
        checkunit: '',
        checktime: '',
        checkreportnum: '',
        // 设计制造信息
        designdep: '',
        designdepnum: '',
        manufacturerid: '',
        manufacturerlic: '',
        productlic: '',
        productnum: '',
        producttime: '',
        // 审核状态
        checkstatus: '',
        // 关联设备
        deviceid: '',
      },
      areacodeDetail: '',
      adminuserdepidDetail: '',
      userdepidDetail: '',
      maintenancedepidDetail: '',
      examinedepidDetail: '',
      testdepidDetail: '',
      rescuedepidDetail: '',
      placeidDetail: '',
      devicetypeidDetail: '',
      brandidDetail: '',
      dragtypeDetail: '',
      estatemodelidDetail: '',
      maintenancetypeDetail: '',
      manufactureridDetail: '',
      maintenancegroupidDetail: '',
      examineLists: {},
      testLists: {},
    }
  },
  watch: {
    'formDatas.areacode'(val) {
      if(val) {
        this.getareacodeDetail(val);
      }else {
        this.areacodeDetail = '';
      }
    },
    'formDatas.adminuserdepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'adminuserdepidDetail');
      }else {
        this.adminuserdepidDetail = '';
      }
    },
    'formDatas.userdepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'userdepidDetail');
      }else {
        this.userdepidDetail = '';
      }
    },
    'formDatas.maintenancedepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'maintenancedepidDetail');
      }else {
        this.maintenancedepidDetail = '';
      }
    },
    'formDatas.examinedepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'examinedepidDetail');
      }else {
        this.examinedepidDetail = '';
      }
    },
    'formDatas.testdepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'testdepidDetail');
      }else {
        this.testdepidDetail = '';
      }
    },
    'formDatas.rescuedepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'rescuedepidDetail');
      }else {
        this.rescuedepidDetail = '';
      }
    },
    'formDatas.placeid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '4', 'placeidDetail');
      }else {
        this.placeidDetail = '';
      }
    },
    'formDatas.devicetypeid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '1', 'devicetypeidDetail');
      }else {
        this.devicetypeidDetail = '';
      }
    },
    'formDatas.brandid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '3', 'brandidDetail');
      }else {
        this.brandidDetail = '';
      }
    },
    'formDatas.dragtype'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '8', 'dragtypeDetail');
      }else {
        this.dragtypeDetail = '';
      }
    },
    'formDatas.estatemodelid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '5', 'estatemodelidDetail');
      }else {
        this.estatemodelidDetail = '';
      }
    },
    'formDatas.manufacturerid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '6', 'manufactureridDetail');
      }else {
        this.manufactureridDetail = '';
      }
    },
    'formDatas.maintenancetype'(val) {
      if(val) {
        this.getDictDetail(val, '14', 'maintenancetypeDetail');
      }else {
        this.maintenancetypeDetail = '';
      }
    },
    'formDatas.maintenancegroupid'(val) {
      if(val) {
        this.getMaintenancegroupDetail(val, 'maintenancegroupidDetail');
      }else {
        this.maintenancegroupidDetail = '';
      }
    },
  },
  created() {
    this.initDetail();
  },
  methods: {
    initDetail() {
      if(this.liftid) {
        let params = {
          liftid: this.liftid
        }
        getLiftinfoById(params).then(res => {
          if(res && res.returncode == '0') {
            for(let key in this.formDatas) {
              if(res.item[key] || res.item[key] === 0) {
                this.formDatas[key] = res.item[key];
              }
            }
            // 使用单位信息
            if(res.item.userdepLists) {
              this.formDatas.userdepid_depnum = res.item.userdepLists.depnum || '';
              this.formDatas.userdepid_businessnum = res.item.userdepLists.businessnum || '';
              this.formDatas.userdepid_address = res.item.userdepLists.address || '';
              this.formDatas.userdepid_mailcode = res.item.userdepLists.mailcode || '';
              this.formDatas.userdepid_safeperson = res.item.userdepLists.safeperson || '';
              this.formDatas.userdepid_safephone = res.item.userdepLists.safephone || '';
              this.formDatas.userdepid_liftpropertyid = res.item.userdepLists.liftpropertyid || '';
            }
            // 维保单位信息
            if(res.item.maintenancedepLists) {
              this.formDatas.maintenancedepid_depnum = res.item.maintenancedepLists.depnum || '';
              this.formDatas.maintenancedepid_certificatenum = res.item.maintenancedepLists.certificatenum || '';
              this.formDatas.maintenancedepid_businessnum = res.item.maintenancedepLists.businessnum || '';
              this.formDatas.maintenancedepid_licensenum = res.item.maintenancedepLists.licensenum || '';
              this.formDatas.maintenancedepid_address = res.item.maintenancedepLists.address || '';
              this.formDatas.maintenancedepid_rescuepreson = res.item.maintenancedepLists.rescuepreson || '';
              this.formDatas.maintenancedepid_rescuephone = res.item.maintenancedepLists.rescuephone || '';
              this.formDatas.maintenancedepid_maintenanceperson1 = res.item.maintenancedepLists.maintenanceperson1 || '';
              this.formDatas.maintenancedepid_maintenancephone1 = res.item.maintenancedepLists.maintenancephone1 || '';
              this.formDatas.maintenancedepid_maintenanceperson2 = res.item.maintenancedepLists.maintenanceperson2 || '';
              this.formDatas.maintenancedepid_maintenancephone2 = res.item.maintenancedepLists.maintenancephone2 || '';
              this.formDatas.maintenancedepid_areamanager = res.item.maintenancedepLists.areamanager || '';
              this.formDatas.maintenancedepid_areamanagerphone = res.item.maintenancedepLists.areamanagerphone || '';
              this.formDatas.maintenancedepid_depmanager = res.item.maintenancedepLists.depmanager || '';
              this.formDatas.maintenancedepid_depmanagerphone = res.item.maintenancedepLists.depmanagerphone || '';
              this.formDatas.maintenancedepid_mailcode = res.item.maintenancedepLists.mailcode || '';
            }
            // 检验信息
            if(res.item.examineLists) {
              this.examineLists = res.item.examineLists;
            }else {
              this.examineLists = {};
            }
            if(res.item.testLists) {
              this.testLists = res.item.testLists;
            }else {
              this.testLists = {};
            }
          }
        })
      }
    },
    getareacodeDetail(areacode) {
      let params = {
        areacode
      };
      getParentTreeBycode(params).then(res => {
        if(res && res.returncode == '0') {
          let treeStr = getTreeDeepString(res.item, '', 'areaname', 'childArea');
          this.areacodeDetail = treeStr.replace(/\|/g, '-');
        }
      })
    },
    getDeptDetail(userdepid, detailKey) {
      let params = {
        userdepid
      };
      getParentTreeByid(params).then(res => {
        if(res && res.returncode == '0') {
          let treeStr = getTreeDeepString(res.item, '', 'userdepname', 'childUserdep');
          this[detailKey] = treeStr.replace(/\|/g, '-');
        }
      })
    },
    getLiftPropertyDetail(val, liftpropertytype, detailKey) {
      let params = {
        liftpropertytype
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          for(let i=0; i<res.item.length; i++) {
            if(res.item[i].liftpropertyid == val) {
              this[detailKey] = res.item[i].value;
              break;
            }
          }
        }
      })
    },
    getDictDetail(val, dictype, detailKey) {
      let params = {
        dictype
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          for(let i=0; i<res.item.length; i++) {
            if(res.item[i].dickey == val) {
              this[detailKey] = res.item[i].dicvalue;
              break;
            }
          }
        }
      })
    },
    getMaintenancegroupDetail(val, detailKey) {
      let params = {
        maintenancedepid: this.formDatas.maintenancedepid
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          for(let i=0; i<res.item.length; i++) {
            if(res.item[i].maintenancegroupid == val) {
              this[detailKey] = res.item[i].maintenancegroupname
              break;
            }
          }
        }
      })
    },
  }
}
</script>