<!--参数设置-->
<template>
  <div>
    <div style="margin-left: -10%;margin-top: 2%">
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="标定楼层">
        <a-input v-model="currentfloor" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="setParamsConfig">确定</a-button>
      </a-form-model-item>
    </a-form-model>
    </div>
  </div>
</template>

<script>
import {setDeviceparamValueList} from "A/ai";

export default {
  name: "DeviceParams",
  props: ['devicenum'],
  data(){
    return {
      currentfloor: '',
    }
  },
  methods :{
    setParamsConfig(){
      let paramsBody = {
        calibrate_floor_num: this.currentfloor,
      };
      console.log(this.devicenum)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: '1',
            modelnum: '',
            params: {
              methord: 'PUT',
              url:'/iot',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
      // }
    },
  }
}
</script>

<style scoped>

</style>