import { render, staticRenderFns } from "./DeviceParams.vue?vue&type=template&id=08ce49f3&scoped=true&"
import script from "./DeviceParams.vue?vue&type=script&lang=js&"
export * from "./DeviceParams.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08ce49f3",
  null
  
)

export default component.exports