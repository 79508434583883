// 大数据辅助决策
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

// 查询物联网故障统计
export function getIotStatistic(params) {
  return http({
    url: `${preApi}/bigdata/getIotStatistic`,
    method: "post",
    data: params
  })
}
// 导出物联网故障统计
export function exportFaultExcel(params) {
  params.filename = '物联网故障统计.xls';
  return http({
    url: `${preApi}/bigdata/exportFaultExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 查询电梯运行数据统计
export function getRundataStatistic(params) {
  return http({
    url: `${preApi}/bigdata/getRundataStatistic`,
    method: "post",
    data: params
  })
}
// 导出电梯运行数据统计
export function exportRundataExcel(params) {
  params.filename = '电梯运行数据统计.xls';
  return http({
    url: `${preApi}/bigdata/exportRundataExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 查询物联网故障统计
export function getRescueStatistic(params) {
  return http({
    url: `${preApi}/bigdata/getRescueStatistic`,
    method: "post",
    data: params
  })
}
// 导出物联网故障统计
export function exportRescueExcel(params) {
  params.filename = '物联网故障统计.xls';
  return http({
    url: `${preApi}/bigdata/exportRescueExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 查询物联网维保故障统计
export function getMaintenanceStatistic(params) {
  return http({
    url: `${preApi}/bigdata/getMaintenanceStatistic`,
    method: "post",
    data: params
  })
}
// 导出物联网维保故障统计
export function exportMaintenanceExcel(params) {
  params.filename = '维修保养统计.xls';
  return http({
    url: `${preApi}/bigdata/exportMaintenanceExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 维保电梯统计
export function getMaintenanceLiftStatistic(params) {
  return http({
    url: `${preApi}/bigdata/getMaintenanceLiftStatistic`,
    method: "post",
    data: params
  })
}
// 导出维保电梯统计
export function exportMaintenanceLiftExcel(params) {
  params.filename = '维修保养统计.xls';
  return http({
    url: `${preApi}/bigdata/exportMaintenanceLiftExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 保养情况统计
export function getMaintenanceMainStatistic(params) {
  return http({
    url: `${preApi}/bigdata/getMaintenanceMainStatistic`,
    method: "post",
    data: params
  })
}
// 导出保养情况统计
export function exportMaintenanceMainExcel(params) {
  params.filename = '维修保养统计.xls';
  return http({
    url: `${preApi}/bigdata/exportMaintenanceMainExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 维修情况统计
export function getMaintenanceKeepStatistic(params) {
  return http({
    url: `${preApi}/bigdata/getMaintenanceKeepStatistic`,
    method: "post",
    data: params
  })
}
// 导出维修情况统计
export function exportMaintenanceKeepExcel(params) {
  params.filename = '维修保养统计.xls';
  return http({
    url: `${preApi}/bigdata/exportMaintenanceKeepExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 人员统计
export function getUserStatistic(params) {
  return http({
    url: `${preApi}/bigdata/getUserStatistic`,
    method: "post",
    data: params
  })
}
// 导出人员统计
export function exportUserStatistic(params) {
  params.filename = '维修保养统计.xls';
  return http({
    url: `${preApi}/bigdata/exportUserStatistic`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 一键生成报告--区域查询
export function getAreaByLift(params) {
  return http({
    url: `${preApi}/area/getAreaByLift`,
    method: "post",
    data: params
  })
}
// 一键生成报告
export function exporRescueWordStatistic(params) {
  params.filename = '工作报告.docx';
  return http({
    url: `${preApi}/bigdata/exporRescueWordStatistic`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 一键生成报告
export function exporIotWordStatistic(params) {
  params.filename = '工作报告.docx';
  return http({
    url: `${preApi}/bigdata/exporIotWordStatistic`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 一键生成报告
export function exporMaintenanceWordStatistic(params) {
  params.filename = '维保报告.docx';
  return http({
    url: `${preApi}/bigdata/exporMaintenanceWordStatistic`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 一键生成报告
export function exportkeepMaintenanceWordStatistic(params) {
  params.filename = '维保工单报告.docx';
  return http({
    url: `${preApi}/bigdata/exportkeepMaintenanceWordStatistic`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 获取温度数据
export function getTemperatureByAreaname(params) {
  return http({
    url: `${preApi}/weather/getTemperatureByAreaname`,
    method: "post",
    data: params,
  })
}
// 获取电梯运行数据
export function getStatusDataCountListByConditon(params) {
  return http({
    url: `${preApi}/lift/getStatusDataCountListByConditon`,
    method: "post",
    data: params,
  })
}