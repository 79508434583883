<template>
  <div id="videoControl" ref="container" class="video-control"></div>
</template>
<script>
import { getRsaMessage } from 'U'
import { mapState } from 'vuex'
import { getPluginParam } from 'A/jcgn'
export default {
  props: {
    width: {
      default: 782
    },
    height: {
      default: 440
    },
    devicenum: {
      default: ''
    }
  },
  beforeDestroy() {
    console.log('beforeDestroy======')
    this.destroy()
  },
  data() {
    return {
      videoObj: null,
      pubKey: '',
      servicePort: {
        start: 15900,
        end: 15909,
      },
      szClassId: '23BF3B0A-2C56-4D97-9C03-0CB103AA8F11',
      initCount: 0,
      appkey: '21611960',
      secret: 'Q7U4a9nmKvokEYj4E7RB',
      ip: '183.214.195.130',
      port: 1443,
      playMode: 0,
      // snapDir: 'D:\\SnapDir',
      // videoDir: 'D:\\VideoDir',
      layout: '1x1',
      enableHTTPS: 1,
      encryptedFields: 'secret',
      showToolbar: 1,
      showSmart: 1,
      buttonIDs: '0,16,256,257,258,259,260,512,513,514,515,516,517,768,769',
      cameraindexcode: '3b0b5063ae7d4aef88784ea3d5b7bf0e',
      stopPlayTimeout: null,
      start:'',
      end:'',
    }
  },
  computed: {
    ...mapState({
      pageScrollInfo: state => state.pageScrollInfo,
      collapsed: state => state.main.collapsed,
    })
  },
  watch: {
    pageScrollInfo(val) {
      if(val) {
        // console.log(val);
        try {
          this.videoObj.JS_Resize(this.width, this.height);
        }catch(e) {}
      }
    },
    collapsed(val) {
      setTimeout(() => {
        try {
          this.videoObj.JS_Resize(this.width, this.height);
        }catch(e) {}
      }, 300)
    },
  },
  mounted() {
    if(this.videoObj) {
      this.videoObj.JS_Disconnect().then(() => {
        this.getParams()
      }, () => {
        this.getParams()
      })
    }else {
      this.getParams();
    }
    window.addEventListener('beforeunload', this.destroy)
  },
  methods: {
    getParams() {
      if(this.devicenum) {
        let params = {
          devicenum: this.devicenum
        };
        getPluginParam(params).then(res => {
          // {
          //   "playmode": "0",
          //   "port": "1443",
          //   "returncode": "0",
          //   "appsecret": "zGfdcnIxwWbXpAXp34CG",
          //   "ip": "183.214.195.130",
          //   "appkey": "21777047",
          //   "cameraindexcode":"543027b982584996863139a41eb4d2bb",
          //   "errormsg": "ok"
          // }
          if(res && res.returncode == '0') {
            this.ip = res.ip;
            this.port = parseInt(res.port, 10);
            this.appkey = res.appkey;
            this.secret = res.appsecret;
            this.cameraindexcode = res.cameraindexcode;
            this.init();
          }
        })
      }
    },
    init(playMode=0, start, end) {
      console.log('===init=====')
      let _this = this;
      this.videoObj = new WebControl({
        szPluginContainer: 'videoControl',
        iServicePortStart: this.servicePort.start,
        iServicePortEnd: this.servicePort.end,
        szClassId: this.szClassId,
        cbConnectSuccess() {
          console.log('cbConnectSuccess==========')
          _this.videoObj.JS_StartService('window', {
            dllPath: "./VideoPluginConnect.dll"
          }).then(() => {
            _this.videoObj.JS_SetWindowControlCallback({
              cbIntegrationCallBack: _this.cbIntegrationCallBack
            })
            _this.videoObj.JS_CreateWnd('videoControl', _this.width, _this.height).then(() => {
              _this.getPubKey(playMode, start, end);
            })
          }, ()=>{})
        },
        cbConnectError() {
          _this.videoObj = null;
          _this.$refs.container.innerHTML = '插件未启动，正在尝试启动，请稍候...';
          _this.videoObj.JS_WakeUp("VideoWebPlugin://");
          _this.initCount++;
          if(_this.initCount < 3) {
            setTimeout(() => {
              _this.init();
            }, 3000);
          }else {
            _this.$refs.container.innerHTML = '插件启动失败，请检查插件是否安装！';
          }
        },
        cbConnectClose() {
          console.log("cbConnectClose=============");
          _this.videoObj = null;
        }
      })
    },
    cbIntegrationCallBack(oData) {
      console.log(oData)
      if(oData.responseMsg.msg.result==769){
        let result=769
        this.$emit('playData',result)
      }
      // console.log(JSON.stringify(oData));
      // console.log(JSON.stringify(oData.responseMsg));
      let res = oData.responseMsg;
      if(res && res.type == 3) {
        // 抓图
        this.$emit('get-snap-shot-iamge', 'data:image/jpeg;base64,'+res.msg.picData);
      }else if(res && res.type == 2 && res.msg.result == '816') {
        this.$emit('video-stop');
      }else if(res && res.type == 2 && res.msg.result == '769') {
        this.$emit('request-fail');
      }
    },
    playBack(start, end) {
      try {
        this.videoObj.JS_DestroyWnd().then(() => {
          this.videoObj = null;
          this.init(1, start, end);
        }, () => {
          this.videoObj = null;
          this.init(1, start, end);
        })
      }catch(e) {}
    },
    playPreview() {
      try {
        this.videoObj.JS_DestroyWnd().then(() => {
          this.videoObj = null;
          this.init();
        }, () => {
          this.videoObj = null;
          this.init();
        })
      }catch(e) {}
    },
    getPubKey(playMode, start, end) {
      this.videoObj.JS_RequestInterface({
        funcName: "getRSAPubKey",
        argument: JSON.stringify({
          keyLength: 1024
        })
      }).then(data => {
        // console.log(data)
        if(data.responseMsg.data) {
          this.pubKey = data.responseMsg.data;
          this.initPlayer(playMode, start, end);
        }
      })
    },
    initPlayer(playMode, start, end) {
      let secret = getRsaMessage(this.secret, this.pubKey);
      let params = {
        appkey: this.appkey,                            //API网关提供的appkey
        secret: secret,                                 //API网关提供的secret
        ip: this.ip,                                    //API网关IP地址
        playMode: playMode,                             //播放模式（决定显示预览还是回放界面）
        port: this.port,                                //端口
        snapDir: this.snapDir,                          //抓图存储路径
        videoDir: this.videoDir,                        //紧急录像或录像剪辑存储路径
        layout: this.layout,                            //布局
        enableHTTPS: this.enableHTTPS,                  //是否启用HTTPS协议
        encryptedFields: this.encryptedFields,          //加密字段
        showToolbar: this.showToolbar,                  //是否显示工具栏
        showSmart: this.showSmart,                      //是否显示智能信息
        buttonIDs: this.buttonIDs,                       //自定义工具条按钮
        reconnectTimes: 3,
        // reconnectDuration: 180,
      };
      // console.log(params)
      this.videoObj.JS_RequestInterface({
        funcName: "init",
        argument: JSON.stringify(params)
      }).then(res => {
        // console.log(res)
        this.videoObj.JS_Resize(this.width, this.height);
        if(playMode == 1) {
          setTimeout(() => {
            this.startPlayBack(this.cameraindexcode, start, end)
          })
        }else {
          this.startPreview(this.cameraindexcode)
        }
        this.stopPlayTimeout = setTimeout(() => {
          this.stopPlayer();
        }, 10*60*1000);
      })
    },
    startPreview(cameraIndexCode) {
      // this.stopPlayBack();
      this.videoObj.JS_RequestInterface({
        funcName: "startPreview",
        argument: JSON.stringify({
          cameraIndexCode,
          streamMode: 0,
          transMode: 0,
          gpuMode: 0,
          wndId: -1,
        })
      })
    },
    startPlayBack(cameraIndexCode, start, end) {
      console.log(start, end)
      // this.stopPreview();
      // this.videoObj.JS_HideWnd();
      this.cameraIndexCode=cameraIndexCode
      this.start=start
      this.end=end
      this.videoObj.JS_RequestInterface({
        funcName: "startPlayback",
        argument: JSON.stringify({
            cameraIndexCode: cameraIndexCode,  //监控点编号
            startTimeStamp: ''+start,          //录像查询开始时间戳，单位：秒
            endTimeStamp: ''+end,              //录像结束开始时间戳，单位：秒
            recordLocation: 1,                 //录像存储类型：0-中心存储，1-设备存储
            protocol: 'rtsp',
            transMode: 0,                      //传输协议：0-UDP，1-TCP
            gpuMode: 1,                        //是否启用GPU硬解，0-不启用，1-启用
            wndId: -1                          //可指定播放窗口
        })
      }).then(res => {
        console.log(res)
        if(res && res.responseMsg && res.responseMsg.code == '0') {
        }else {
          console.log("请求失败")
          this.videoObj.JS_RequestInterface({
            funcName: "startPlayback",
            argument: JSON.stringify({
              cameraIndexCode: cameraIndexCode,  //监控点编号
              startTimeStamp: ''+start,          //录像查询开始时间戳，单位：秒
              endTimeStamp: ''+end,              //录像结束开始时间戳，单位：秒
              recordLocation: 1,                 //录像存储类型：0-中心存储，1-设备存储
              protocol: 'rtmp',
              transMode: 0,                      //传输协议：0-UDP，1-TCP
              gpuMode: 0,                        //是否启用GPU硬解，0-不启用，1-启用
              wndId: -1                          //可指定播放窗口
            })
          })
        }
      });
    },
    stopPlayer() {
      if(this.stopPlayTimeout) {
        clearTimeout(this.stopPlayTimeout);
        this.stopPlayTimeout = null;
      }
      this.stopPreview();
      this.stopPlayBack();
    },
    stopPreview() {
      this.videoObj.JS_RequestInterface({
        funcName: "stopAllPreview"
      });
    },
    stopPlayBack() {
      this.videoObj.JS_RequestInterface({
        funcName: "stopAllPlayback"
      });
    },
    cuttingPlayer(iLeft, iTop, iWidth, iHeight) {
      this.videoObj.JS_CuttingPartWindow(iLeft, iTop, iWidth, iHeight);
    },
    repairPlayer(iLeft, iTop, iWidth, iHeight) {
      this.videoObj.JS_RepairPartWindow(iLeft, iTop, iWidth, iHeight);
    },
    hidePlayer() {
      this.videoObj.JS_HideWnd();
    },
    showPlayer() {
      this.videoObj.JS_ShowWnd();
    },
    snapShot() {
      this.videoObj.JS_RequestInterface({
        funcName: "snapShot",
        argument: JSON.stringify({
          name: 'C:\\Program Files (x86)\\VideoWebPlugin\\SnapDir\\mark.jpg'
        })
      }).then(res => {
        console.log(res)
        if(res && res.responseMsg && res.responseMsg.code == '0') {
          this.$emit('snap-shot', 'success')
        }else {
          this.$emit('snap-shot', 'fail')
          // this.$message.error('抓图失败');
        }
      });
    },
    destroy() {
      console.log('===================VideoControl destroy===========')
      try {
        this.videoObj.JS_HideWnd();
        this.videoObj.JS_Disconnect().then(() => {
          this.videoObj = null;
        }, () => {
          this.videoObj = null;
        })
      }catch(e) {}
    }
  }
}
</script>