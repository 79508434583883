import { Plugin } from 'xgplayer'

const { POSITIONS } = Plugin

const DefaultName = "录像";

// VideoPlugin.js
export default class VideoPlugin extends Plugin {
    // 插件的名称，将作为插件实例的唯一key值
    static get pluginName() {
        return 'VideoPlugin'
    }

    static get defaultConfig() {
        return {
            // 挂载在controls的右侧，如果不指定则默认挂载在播放器根节点上
            position: POSITIONS.CONTROLS_RIGHT,
            index: 1 // 位置索引为1
        }
    }

    constructor(args) {
        super(args)
        this.NAME ="录像"
    }

    beforePlayerInit() {
        // TODO 播放器调用start初始化播放源之前的逻辑
    }

    afterPlayerInit() {
        // TODO 播放器调用start初始化播放源之后的逻辑
    }

    afterCreate() {
        this.icon = this.find('.icon')
        this.onIconClick = () => {
            console.log('class为icon元素点击回调')
        }
        this.onClick = () => {
            this.showVideo(this.NAME)
            if(this.NAME==='录像'){
                this.NAME='完成'
            }else{
                this.NAME='录像'
            }
        }
        // 对当前插件根节点内部类名为.icon的元素绑定click事件
        this.bind('.icon', 'click', this.onIconClick)
        // 对当前插件根节点绑定click事件
        this.bind('click', this.onClick)
        //TODO 插件实例化之后的一些逻辑
    }

    showVideo(data){
        window.functionForJs(data)
    }

    destroy() {
        this.unbind('.icon', 'click', this.onIconClick)
        this.unbind('click', this.onClick)
        this.icon = null
        // 播放器销毁的时候一些逻辑
    }

    render() {
        return `<div class="video-plugin" style="
                   margin-top: 5px;
                   margin-right: 15px;
                   display: inline-block;
                   padding: 5px 15px 5px 15px; /* 设置内边距，可根据需要调整 */  
                   background-color: rgba(0,0,0,0.33); /* 设置背景色，这里使用蓝色作为示例 */  
                   color: #ffffff; /* 设置文本颜色为白色 */  
                   border-radius: 15px; /* 设置圆角，可根据需要调整 */  
                   text-align: center; /* 设置文本水平居中 */ ">
                   ${DefaultName}<div class="icon"></div>
                </div>`
    }
}