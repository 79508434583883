<!--故障事件图片弹窗-->
<template>
  <a-affix :style="{position:'absolute',top:'110px',left:'85%',zIndex:'99999999999'}">
  <a-modal v-model="modalVisible" title="查看图片" width="800px"  :footer="null" :centered="true" @cancel="cancelModal" :bodyStyle="bodyStyle" :dialogStyle="dialogStyle">
    <div id="viewUserImg" STYLE="display:flex">
      <div><a-icon type="left-circle" style="font-size: 50px;margin: 150px 10px 0 -10px" @click="lastPic"/></div>
      <img class="imgWH" :src="picUrl"/>
      <div><a-icon type="right-circle" style="font-size: 50px;margin: 150px -10px 0 10px" @click="nextPic"/></div>
    </div>
<!--    <a-button type="primary" size="small" @click="downloadImage(picUrl)" style="margin-top: 10px;margin-left: 44%">下载到本地</a-button>-->
  </a-modal>
  </a-affix>
</template>

<script>
import {viewUserImg} from "A/thjlcx";
export default{
  name: 'imageBack',
  props: {
    EventData:{
      default: null
    },
    visible: {
      default: false
    },
  },
  data(){
    return {
      modalVisible:false,
      fileField:'',
      picUrl:'',
      baseurl:'https://esp.u-lake.com/',
      urlList:[],
      filename:'',
      bodyStyle:{
      },
      dialogStyle:{
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getPic();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods: {
    cancelModal(e) {
      this.modalVisible = false
      this.urlList=[]
      this.picUrl=''
    },
    init() {
      this.urlList=[];
      this.getPic();
    },
    sleep(ms) { //sleep延迟方法
      var unixtime_ms = new Date().getTime();
      while(new Date().getTime() < unixtime_ms + ms) {}
    },
    lastPic(){
      if(this.picUrl==(this.baseurl+this.urlList[0].picurl)){
        this.$message.error("没有上一张图片了！")
      }else if(this.picUrl==(this.baseurl+this.urlList[1].picurl)){
        if(this.urlList[0].picurl){
          this.picUrl=this.baseurl+this.urlList[0].picurl
        }else{this.$message.error("没有上一张图片了！")}
      }else if(this.picUrl==(this.baseurl+this.urlList[2].picurl)){
        if(this.urlList[1].picurl){
          this.picUrl=this.baseurl+this.urlList[1].picurl
        }else{this.$message.error("没有上一张图片了！")}
      }
    },
    nextPic(){
      if(this.picUrl==(this.baseurl+this.urlList[0].picurl)){
        if(this.urlList[1].picurl){
          this.picUrl=this.baseurl+this.urlList[1].picurl
        }else{this.$message.error("没有下一张图片了！")}
      }else if(this.picUrl==(this.baseurl+this.urlList[1].picurl)){
        if(this.urlList[2].picurl){
          this.picUrl=this.baseurl+this.urlList[2].picurl
        }else{this.$message.error("没有下一张图片了！")}
      }else if(this.picUrl==(this.baseurl+this.urlList[2].picurl)){
        this.$message.error("没有下一张图片了！")
      }
    },
    downloadToloc(){
      if(this.EventData.isturned=='1'){
        this.filename=this.EventData.devicenum+"_"+"3"+"_"+"06"+"_"+this.EventData.eventstarttime+".jpg"
      }else{
        this.filename=this.EventData.devicenum+"_"+this.EventData.eventtype+"_"+this.EventData.eventcode+"_"+this.EventData.eventstarttime+".jpg"
      }
      this.downloadFile(this.filename,this.picUrl)
    },
    getPic() {
      var that = this;
      let params = {
        devicenum: that.EventData.devicenum,
        eventrecordid:that.EventData.eventrecordid
      }
      viewUserImg(params).then((res) => {
        if (res.returncode == '1') {
          console.log("图片丢失或没有相关图片")
        } else {
          that.baseurl=res.baseurl
          that.urlList=res.url
          if(that.urlList.length>0){
            if (that.urlList[0]) {
              that.picUrl = that.baseurl+that.urlList[0].picurl
            } else if (that.urlList[1]) {
              that.picUrl = that.baseurl+that.urlList[1].picurl
            } else if (that.urlList[2]) {
              that.picUrl = that.baseurl+that.urlList[2].picurl
            }
          }else {
            that.urlList = []
            that.$message.error("图片丢失或没有相关图片")
            that.modalVisible = false
          }

        }
      })
      // for (let i = 1; i <= 3; i++) {
      //   (function (i) {
      //     setTimeout(function () {
      //       let params = {
      //         devicenum: that.EventData.devicenum,
      //         eventtype: that.EventData.isturned=='1'?'3':that.EventData.eventtype,
      //         eventcode: that.EventData.isturned=='1'?'06':that.EventData.eventcode,
      //         eventstarttime: that.EventData.eventstarttime,
      //         picNum: i
      //       }
      //       //请求图片接口并且对文件流进行处理
      //       viewUserImg(params).then((res) => {
      //         // var data = res.data;
      //         if (res.returncode == '1') {
      //           console.log("丢失")
      //         }else {
      //           console.log(res)
      //           that.urlList.push(res.url)
      //           if(that.urlList[0]){
      //             that.picUrl=that.urlList[0]
      //           }else if (that.urlList[1]) {
      //             that.picUrl = that.urlList[1]
      //           } else if (that.urlList[2]) {
      //             that.picUrl = that.urlList[2]
      //           } else {
      //             that.urlList=[]
      //             // that.$message.error("没有相关图片！")
      //             that.modalVisible=false
      //           }
      //
      //           // this.fileField =
      //           //     "data:image/jpg;base64," +
      //           //     btoa(
      //           //         new Uint8Array(res).reduce(
      //           //             (res, byte) => res + String.fromCharCode(byte),
      //           //             ""
      //           //         )
      //           //     );
      //           // that.urlList.push(this.fileField)
      //           // if(that.urlList[0].length>1000){
      //           //   that.picUrl=that.urlList[0]
      //           // }else if (that.urlList[1].length>1000) {
      //           //   that.picUrl = that.urlList[1]
      //           // } else if (that.urlList[2].length>1000) {
      //           //   that.picUrl = that.urlList[2]
      //           // } else {
      //           //   that.urlList=[]
      //           //   that.$message.error("没有相关图片！")
      //           //   that.modalVisible=false
      //           // }
      //         }
      //       })
      //     }, 250 * i);
      //   })(i);
      // }
    },
    downloadImage(imgSrc) {
      const image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.src = imgSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.download = imgSrc || "photo";
          a.href = url;
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        });
      };
    },
    //下载图片
    downloadFile(fileName, content) {
      let aLink = document.createElement('a');
      let blob = this.base64ToBlob(content); //new Blob([content]);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      // aLink.dispatchEvent(evt);
      aLink.click()
    },
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {type: contentType});
    }
  }
}
</script>
<style lang="scss" scoped>
.imgWH{
  width: 87%;
  height: auto;

  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
</style>
